<template>
  <v-theme-provider
    class="grey lighten-4">
    <base-section
      id="com-syariah"
      space="40"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <base-section-heading title="Dewan Pengawas Syariah" />
            <base-body space="0">
               <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="card in cards"
                    :key="card.name"
                    md="4"
                    xs="12"
                  >
                    <div 
                      data-aos="zoom-in"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-mirror="true"
                      data-aos-once="true"
                      data-aos-anchor-placement="top-center">
                    <v-card
                    outlined>
                      <v-img
                        :src="require(`@/assets/${card.src}`)"
                        class="white--text align-end"
                        height="400px"
                        :alt="card.name"
                      >
                        <v-card-title class="body-1" v-text="card.name"></v-card-title>
                      </v-img>
                      <v-card-actions>
                        {{ card.title }}
                      </v-card-actions>
                    </v-card>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionComSyariah',
    data: () => ({
            posts: [],
            cards: [
              { name: 'DR. H. Anwar Abbas, M.M, M.AG', title: 'Ketua Dewan Pengawas Syariah', src: 'AnwarAbbasDPS.jpg' },
              { name: 'Amin Musa, SE, QRMA', title: 'Anggota Dewan Pengawas Syariah', src: 'AminMusaDPS.jpg' },
            ],
        }),
  }
</script>
